import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  Box,
  FormControl,
  FormLabel,
  Select,
  Textarea,
  FormErrorMessage,
} from "@chakra-ui/react";

import { useHistory } from 'react-router-dom';
import { API_BASE_URL } from "config";  // ensure this points to your backend
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

function Campaigns() {
  const history = useHistory();
  const [campaigns, setCampaigns] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // Chakra UI modal controls
  const { isOpen, onOpen, onClose } = useDisclosure();

  // For editing vs. adding new
  const [editingCampaign, setEditingCampaign] = useState(null);

  // Form data
  const [formData, setFormData] = useState({
    name: '',
    budget: '',
    targetAudience: {
      age: { min: '', max: '' },
      gender: '',
      interests: []
    },
    startDate: '',
    endDate: ''
  });

  // Validation errors
  const [errors, setErrors] = useState({});

  // Fetch all campaigns
  const fetchCampaigns = async () => {
    try {
      const token = localStorage.getItem('advertiserToken');
      const response = await fetch(`${API_BASE_URL}/advertiser/campaigns`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      });

      if (!response.ok) {
        // If token is invalid or not provided
        if (response.status === 401) {
          history.push('/advertiser-auth/login');
          return;
        }
        throw new Error("Failed to fetch campaigns");
      }

      const data = await response.json();
      setCampaigns(data || []);
    } catch (error) {
      console.error("Error fetching campaigns:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchCampaigns();
  }, []);

  // Open modal to add a new campaign
  const handleAddCampaign = () => {
    setEditingCampaign(null);
    setFormData({
      name: '',
      budget: '',
      targetAudience: '',
      startDate: '',
      endDate: ''
    });
    setErrors({});
    onOpen();
  };

  // Open modal to edit a campaign
  const handleEdit = (campaign) => {
    setEditingCampaign(campaign);
    setFormData({
      name: campaign.name || '',
      budget: campaign.budget || '',
      targetAudience: campaign.targetAudience || '',
      startDate: campaign.startDate
        ? campaign.startDate.substring(0, 10) // e.g. '2024-12-30'
        : '',
      endDate: campaign.endDate
        ? campaign.endDate.substring(0, 10)
        : ''
    });
    setErrors({});
    onOpen();
  };

  // Delete a campaign
  const handleDelete = async (campaign) => {
    try {
      const token = localStorage.getItem('advertiserToken');
      const response = await fetch(`${API_BASE_URL}/advertiser/campaigns/${campaign._id}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        if (response.status === 401) {
          history.push('/advertiser-auth/login');
          return;
        }
        throw new Error('Failed to delete campaign');
      }

      // Re-fetch campaigns & show toast
      await fetchCampaigns();
      toast.success('Campaign deleted successfully!');
    } catch (error) {
      console.error('Error deleting campaign:', error);
      toast.error('Failed to delete campaign.');
    }
  };

  // Handle form input changes
  // const handleChange = (e) => {
  //   setFormData({ ...formData, [e.target.name]: e.target.value });
  // };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "minAge" || name === "maxAge") {
      setFormData(prev => ({
        ...prev,
        targetAudience: {
          ...prev.targetAudience,
          age: { ...prev.targetAudience.age, [name]: Number(value) }
        }
      }));
    } else if (name === "interests") {
      setFormData(prev => ({
        ...prev,
        targetAudience: {
          ...prev.targetAudience,
          interests: value.split(',').map(interest => interest.trim())
        }
      }));
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  // Form validation
  const validateForm = () => {
    const newErrors = {};

    if (!formData.name) newErrors.name = 'Name is required';
    if (!formData.budget) newErrors.budget = 'Budget is required';
    if (!formData.targetAudience) newErrors.targetAudience = 'Target Audience is required';
    if (!formData.startDate) newErrors.startDate = 'Start date is required';
    if (!formData.endDate) newErrors.endDate = 'End date is required';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Submit form for CREATE or UPDATE
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    // Decide whether it's create or update
    const isEditing = !!editingCampaign;
    const method = isEditing ? 'PUT' : 'POST';
    const url = isEditing
      ? `${API_BASE_URL}/advertiser/campaigns/${editingCampaign._id}`
      : `${API_BASE_URL}/advertiser/campaigns`;

    try {
      const token = localStorage.getItem('advertiserToken');
      const response = await fetch(url, {
        method,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          name: formData.name,
          budget: formData.budget,
          // targetAudience: formData.targetAudience,
          targetAudience: {
            age: {
              min: formData.targetAudience.age.min,
              max: formData.targetAudience.age.max
            },
            gender: formData.targetAudience.gender,
            interests: formData.targetAudience.interests
          },
          startDate: formData.startDate,
          endDate: formData.endDate
        }),
      });

      if (!response.ok) {
        // Token might be expired
        if (response.status === 401) {
          history.push('/advertiser-auth/login');
          return;
        }
        // Handle validation errors from the backend
        const errorData = await response.json();
        if (errorData && errorData.errors) {
          setErrors(errorData.errors);
        }
        throw new Error('Failed to save campaign');
      }

      // Close modal, re-fetch campaigns, and possibly show toast
      onClose();
      await fetchCampaigns();
      if (isEditing) {
        toast.success('Campaign updated successfully!');
      } else {
        toast.success('Campaign created successfully!');
      }
    } catch (error) {
      console.error('Error saving campaign:', error);
      toast.error('Failed to save campaign.');
    }
  };

  return (
    <Flex direction='column' pt={{ base: "120px", md: "75px" }}>
      <Button onClick={handleAddCampaign} alignSelf='flex-end' mb='4' colorScheme='blue'>
        Add Campaign
      </Button>

      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb='0px'>
        <CardHeader p='6px 0px 22px 0px'>
          <Text fontSize='lg' fontWeight='bold' color='white'>
            Campaigns List
          </Text>
        </CardHeader>
        <CardBody>
          <Table variant='simple' color='white'>
            <Thead>
              <Tr>
                <Th color='white'>Name</Th>
                <Th color='white'>Budget</Th>
                <Th color='white'>Target Audience</Th>
                <Th color='white'>Start Date</Th>
                <Th color='white'>End Date</Th>
                <Th color='white'>Status</Th>
                <Th color='white'>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {!isLoading && campaigns.map((campaign) => (
                <Tr key={campaign._id}>
                  <Td>{campaign.name}</Td>
                  <Td>{campaign.budget}</Td>
                   {/* Fix for Target Audience rendering */}
                <Td>
                    {typeof campaign.targetAudience === "string" 
                        ? campaign.targetAudience
                        : (
                            <Box>
                                <Text><strong>Age:</strong> {campaign.targetAudience.age?.min} - {campaign.targetAudience.age?.max}</Text>
                                <Text><strong>Gender:</strong> {campaign.targetAudience.gender}</Text>
                                <Text><strong>Interests:</strong> {campaign.targetAudience.interests?.join(', ')}</Text>
                            </Box>
                        )
                    }
                </Td>
                  {/* <Td>{campaign.targetAudience}</Td> */}
                  {/* 
                    If your dates come back as ISO strings (e.g. "2025-01-01T00:00:00.000Z"),
                    you can slice or convert them as you like:
                  */}
                  <Td>{campaign.startDate ? campaign.startDate.substring(0, 10) : ''}</Td>
                  <Td>{campaign.endDate ? campaign.endDate.substring(0, 10) : ''}</Td>
                  <Td>{campaign.status}</Td>
                  <Td>
                    <Button colorScheme='blue' onClick={() => handleEdit(campaign)}>
                      Edit
                    </Button>
                    <Button colorScheme='red' ml={2} onClick={() => handleDelete(campaign)}>
                      Delete
                    </Button>
                    <ToastContainer />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </CardBody>
      </Card>

      {/* Modal for creating/editing a campaign */}
      <Modal isOpen={isOpen} onClose={onClose} size="2xl">
        <ModalOverlay />
        <ModalContent>
          <form onSubmit={handleSubmit}>
            <ModalHeader>
              {editingCampaign ? 'Edit Campaign' : 'Add a New Campaign'}
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              {/* Campaign Name */}
              <FormControl isInvalid={!!errors.name} mb={4}>
                <FormLabel>Name</FormLabel>
                <Input
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder="Campaign name"
                />
                {errors.name && <FormErrorMessage>{errors.name}</FormErrorMessage>}
              </FormControl>

              {/* Budget */}
              <FormControl isInvalid={!!errors.budget} mb={4}>
                <FormLabel>Budget</FormLabel>
                <Input
                  name="budget"
                  type="number"
                  value={formData.budget}
                  onChange={handleChange}
                  placeholder="Budget"
                />
                {errors.budget && <FormErrorMessage>{errors.budget}</FormErrorMessage>}
              </FormControl>

              {/* Target Audience */}
              <Box border="1px" borderColor="gray.200" borderRadius="md" p={4} mb={4}>
                <FormLabel fontSize="lg">Target Audience</FormLabel>
                <hr></hr>
              <FormControl mb={4}>
                <FormLabel>Age Range</FormLabel>
                <Flex>
                  <Input
                    type="number"
                    placeholder="Min Age"
                    name="minAge"
                    value={formData.targetAudience.age?.min || ''}
                    onChange={(e) => setFormData({
                      ...formData,
                      targetAudience: {
                        ...formData.targetAudience,
                        age: { ...formData.targetAudience.age, min: Number(e.target.value) }
                      }
                    })}
                  />
                  <Input
                    type="number"
                    placeholder="Max Age"
                    name="maxAge"
                    value={formData.targetAudience.age?.max || ''}
                    onChange={(e) => setFormData({
                      ...formData,
                      targetAudience: {
                        ...formData.targetAudience,
                        age: { ...formData.targetAudience.age, max: Number(e.target.value) }
                      }
                    })}
                  />
                </Flex>
              </FormControl>

              <FormControl mb={4}>
                <FormLabel>Gender</FormLabel>
                <Select
                  name="gender"
                  value={formData.targetAudience.gender || ''}
                  onChange={(e) => setFormData({
                    ...formData,
                    targetAudience: { ...formData.targetAudience, gender: e.target.value }
                  })}
                >
                  <option value="">Select Gender</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Other">Other</option>
                </Select>
              </FormControl>

              <FormControl mb={4}>
                <FormLabel>Interests</FormLabel>
                <Input
                  name="interests"
                  placeholder="Enter interests separated by commas"
                  value={formData.targetAudience.interests?.join(', ') || ''}
                  onChange={(e) => setFormData({
                    ...formData,
                    targetAudience: {
                      ...formData.targetAudience,
                      interests: e.target.value.split(',').map((interest) => interest.trim())
                    }
                  })}
                />
              </FormControl>
              </Box>

              {/* <FormControl isInvalid={!!errors.targetAudience} mb={4}>
                <FormLabel>Target Audience</FormLabel>
                <Input
                  name="targetAudience"
                  type="number"
                  value={formData.targetAudience}
                  onChange={handleChange}
                  placeholder="Target audience size"
                />
                {errors.targetAudience && (
                  <FormErrorMessage>{errors.targetAudience}</FormErrorMessage>
                )}
              </FormControl> */}

              {/* Start Date */}
              <FormControl isInvalid={!!errors.startDate} mb={4}>
                <FormLabel>Start Date</FormLabel>
                <Input
                  type="date"
                  name="startDate"
                  value={formData.startDate}
                  onChange={handleChange}
                />
                {errors.startDate && <FormErrorMessage>{errors.startDate}</FormErrorMessage>}
              </FormControl>

              {/* End Date */}
              <FormControl isInvalid={!!errors.endDate} mb={4}>
                <FormLabel>End Date</FormLabel>
                <Input
                  type="date"
                  name="endDate"
                  value={formData.endDate}
                  onChange={handleChange}
                />
                {errors.endDate && <FormErrorMessage>{errors.endDate}</FormErrorMessage>}
              </FormControl>
            </ModalBody>

            <ModalFooter>
              <Button colorScheme="blue" mr={3} type="submit">
                {editingCampaign ? 'Update' : 'Submit'}
              </Button>
              <Button colorScheme="blue" variant="outline" onClick={onClose}>
                Cancel
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </Flex>
  );
}

export default Campaigns;

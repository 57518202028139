import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Select,
} from "@chakra-ui/react";

import { useHistory } from 'react-router-dom';
import { API_BASE_URL } from "config"; // Ensure this points to your backend
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

function AdSlots() {
  const history = useHistory();
  const [adSlots, setAdSlots] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // Chakra UI modal controls
  const { isOpen, onOpen, onClose } = useDisclosure();

  // For editing vs. adding new
  const [editingAdSlot, setEditingAdSlot] = useState(null);

  // Form data
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    status: 'Active',
    campaignId: ''
  });

  // Validation errors
  const [errors, setErrors] = useState({});



  
  // Fetch all ad slots
  const fetchAdSlots = async () => {
    try {
      const token = localStorage.getItem('publisherToken');
      const response = await fetch(`${API_BASE_URL}/publisher/ad-slots`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        if (response.status === 401) {
          history.push('/publisher-auth/login');
          return;
        }
        throw new Error("Failed to fetch ad slots");
      }

      const data = await response.json();
      setAdSlots(data || []);
    } catch (error) {
      console.error("Error fetching ad slots:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAdSlots();
    
  }, []);

  // Open modal to add a new ad slot
  const handleAddAdSlot = () => {
    setEditingAdSlot(null);
    setFormData({
      name: '',
      description: '',
      status: 'Active',
      campaignId: '',
    });
    setErrors({});
    onOpen();
  };

  // Open modal to edit an ad slot
  const handleEdit = (adSlot) => {
    setEditingAdSlot(adSlot);
    setFormData({
      name: adSlot.name || '',
      description: adSlot.description || '',
      status: adSlot.status || 'Active',
      campaignId: adSlot.campaignId || '',
    });
    setErrors({});
    onOpen();
  };

  // Delete an ad slot
  // const handleDelete = async (adSlot) => {
  //   try {
  //     const token = localStorage.getItem('publisherToken');
  //     const response = await fetch(`${API_BASE_URL}/publisher/ad-slots/${adSlot._id}`, {
  //       method: 'DELETE',
  //       headers: {
  //         'Authorization': `Bearer ${token}`,
  //       },
  //     });

  //     if (!response.ok) {
  //       if (response.status === 401) {
  //         history.push('/publisher-auth/login');
  //         return;
  //       }
  //       throw new Error('Failed to delete ad slot');
  //     }

  //     // Re-fetch ad slots & show toast
  //     await fetchAdSlots();
  //     toast.success('Ad slot deleted successfully!');
  //   } catch (error) {
  //     console.error('Error deleting ad slot:', error);
  //     toast.error('Failed to delete ad slot.');
  //   }
  // };

  // Handle form input changes
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Form validation
  const validateForm = () => {
    const newErrors = {};
    if (!formData.name) newErrors.name = 'Name is required';
    if (!formData.description) newErrors.description = 'Description is required';
    if (!formData.campaignId) newErrors.campaignId = 'Campaign is required';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Submit form for CREATE or UPDATE
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    const isEditing = !!editingAdSlot;
    const method = isEditing ? 'PUT' : 'POST';
    const url = isEditing
      ? `${API_BASE_URL}/publisher/ad-slots/${editingAdSlot._id}`
      : `${API_BASE_URL}/publisher/ad-slots`;

    try {
      const token = localStorage.getItem('publisherToken');
      const response = await fetch(url, {
        method,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          name: formData.name,
          description: formData.description,
          status: formData.status,
          campaignId: formData.campaignId
        }),
      });

      if (!response.ok) {
        if (response.status === 401) {
          history.push('/publisher-auth/login');
          return;
        }
        throw new Error('Failed to save ad slot');
      }

      onClose();
      await fetchAdSlots();
      toast.success(isEditing ? 'Ad slot updated successfully!' : 'Ad slot created successfully!');
    } catch (error) {
      console.error('Error saving ad slot:', error);
      toast.error('Failed to save ad slot.');
    }
  };

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Button onClick={handleAddAdSlot} alignSelf="flex-end" mb="4" colorScheme="blue">
        Add Ad Slot
      </Button>

      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="lg" fontWeight="bold" color="white">
            Ad Slots List
          </Text>
        </CardHeader>
        <CardBody>
          <Table variant="simple" color="white">
            <Thead>
              <Tr>
                <Th color="white">Name</Th>
                <Th color="white">Description</Th>
                <Th color="white">Status</Th>
                <Th color="white">Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {!isLoading &&
                adSlots.map((adSlot) => (
                  <Tr key={adSlot._id}>
                    <Td>{adSlot.name}</Td>
                    <Td>{adSlot.description}</Td>
                    <Td>{adSlot.status}</Td>
                    <Td>
                      <Button colorScheme="blue" onClick={() => handleEdit(adSlot)}>
                        Edit
                      </Button>
                      {/* <Button colorScheme="red" ml={2} onClick={() => handleDelete(adSlot)}>
                        Delete
                      </Button> */}
                      <ToastContainer />
                    </Td>
                  </Tr>
                ))}
            </Tbody>
          </Table>
        </CardBody>
      </Card>

      {/* Modal for creating/editing an ad slot */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <form onSubmit={handleSubmit}>
            <ModalHeader>
              {editingAdSlot ? 'Edit Ad Slot' : 'Add a New Ad Slot'}
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>

              
              {/* Name */}
              <FormControl isInvalid={!!errors.name} mb={4}>
                <FormLabel>Name</FormLabel>
                <Input
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder="Ad slot name"
                />
                {errors.name && <FormErrorMessage>{errors.name}</FormErrorMessage>}
              </FormControl>

              {/* Description */}
              <FormControl isInvalid={!!errors.description} mb={4}>
                <FormLabel>Description</FormLabel>
                <Input
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                  placeholder="Description"
                />
                {errors.description && <FormErrorMessage>{errors.description}</FormErrorMessage>}
              </FormControl>

              {/* Status */}
              <FormControl mb={4}>
                <FormLabel>Status</FormLabel>
                <Select name="status" value={formData.status} onChange={handleChange}>
                  <option value="Active">Active</option>
                  <option value="Inactive">Inactive</option>
                </Select>
              </FormControl>
            </ModalBody>

            <ModalFooter>
              <Button colorScheme="blue" mr={3} type="submit">
                {editingAdSlot ? 'Update' : 'Submit'}
              </Button>
              <Button colorScheme="blue" variant="outline" onClick={onClose}>
                Cancel
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </Flex>
  );
}

export default AdSlots;

export const API_BASE_URL = 'https://www.portal.seiky.in/seiky-backend/api';
export const API_BASE_URL_ADMIN = 'https://www.portal.seiky.in/seiky-backend';
// export const API_BASE_URL = 'http://localhost:5000/api';

// export const API_BASE_URL = 'http://10.1.0.51:5000/api';



// https://www.portal.seiky.in/seiky-backend/
// http://13.201.86.178/seiky-backend
// http://localhost:3000


// src/components/Sdk.js

import React, { useState } from "react";
import {
  Box,
  Flex,
  Text,
  IconButton,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import { FaCopy, FaCheck } from "react-icons/fa";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { coy } from "react-syntax-highlighter/dist/esm/styles/prism";

const Sdk = () => {
  const toast = useToast();
  const [isCopied, setIsCopied] = useState(false);

  // Define the SDK JavaScript code as a string
  const sdkCode = `(function (window, document) {
    var AdSDK = {
        publisherKey: null,

        init: function (publisherKey) {
            this.publisherKey = publisherKey;
        },

        displayAd: async function (adSlotId) {
            try {
                const response = await fetch(\`http://localhost:5000/api/publisher/ads/fetch?slotId=\${adSlotId}&publisherKey=\${this.publisherKey}\`);
                if (!response.ok) throw new Error("Failed to fetch ad data");

                const adData = await response.json();
                if (adData && adData.content) {
                    // Track impression when ad is displayed
                    this.trackImpression(adData._id);

                    // Display the ad and add click tracking
                    document.getElementById('ad-content').innerHTML = \`
                        <a href="\${adData.targetUrl}" target="_blank" onclick="AdSDK.trackClick('\${adData._id}')">
                            <img src="\${adData.content}" alt="Ad">
                        </a>
                    \`;
                }
            } catch (error) {
                console.error("Error displaying ad:", error);
            }
        },

        trackImpression: async function (adId) {
            try {
                await fetch('http://localhost:5000/api/publisher/analytics/impression', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ adId, publisherKey: this.publisherKey })
                });
            } catch (error) {
                console.error("Error tracking impression:", error);
            }
        },

        trackClick: async function (adId) {
            try {
                await fetch('http://localhost:5000/api/publisher/analytics/click', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ adId, publisherKey: this.publisherKey })
                });
                // console.log("Click tracked successfully");
            } catch (error) {
                console.error("Error tracking click:", error);
            }
        }
    };

    window.AdSDK = AdSDK;
})();

// ✅ Initialize SDK and Display Ad
AdSDK.init('Api-Key');
AdSDK.displayAd('Slot-Id');`;

  // Function to handle copying the SDK code to clipboard
  const handleCopySDKCode = () => {
    navigator.clipboard
      .writeText(sdkCode)
      .then(() => {
        setIsCopied(true);
        toast({
          title: "Copied",
          description: "SDK code has been copied to clipboard.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        // Reset the copied state after 3 seconds
        setTimeout(() => setIsCopied(false), 3000);
      })
      .catch(() => {
        toast({
          title: "Error",
          description: "Failed to copy SDK code.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });
  };

  return (
    <Flex justify="center" align="center" p={4}>
      <Box
        maxW="1930px"
        h="100%"
        maxH="1500px"
        w="100%"
        boxShadow="lg"
        borderRadius="md"
        p={6}
      >
        {/* SDK Code Card */}
        <Box
          bg="gray.50"
          p={4}
          borderRadius="md"
          boxShadow="sm"
          position="relative"
        >
          <Flex justify="space-between" align="center" mb={2}>
            <Text fontSize="lg" fontWeight="bold">
              AdSDK JavaScript Code
            </Text>
            <Tooltip label="Copy SDK Code" aria-label="Copy SDK Code">
              <IconButton
                aria-label="Copy SDK Code"
                icon={isCopied ? <FaCheck color="green" /> : <FaCopy />}
                variant="ghost"
                onClick={handleCopySDKCode}
              />
            </Tooltip>
          </Flex>
          <Box
            bg="blackAlpha.50"
            p={3}
            borderRadius="md"
            overflowX="auto"
            fontFamily="monospace"
            fontSize="sm"
            whiteSpace="pre-wrap"
            maxH="400px"
          >
            <SyntaxHighlighter language="javascript" style={coy}>
              {sdkCode}
            </SyntaxHighlighter>
          </Box>
        </Box>
      </Box>
    </Flex>
  );
};

export default Sdk;

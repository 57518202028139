import React, { useState, useEffect } from "react";
import {
  Flex,
  Button,
  FormControl,
  FormLabel,
  Select,
  Input,
  Textarea,
  FormErrorMessage,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Table,
  Thead,
  Tbody,
  Th,
  Tr,
  Td
} from "@chakra-ui/react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";

// Replace with your actual base URL
const API_BASE_URL = "https://www.portal.seiky.in/seiky-backend/api";

export default function Ads() {
  const history = useHistory();

  // Campaign list for the dropdown
  const [campaigns, setCampaigns] = useState([]);
  // Ads list to display
  const [ads, setAds] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // Modal state
  const { isOpen, onOpen, onClose } = useDisclosure();

  // Tracking whether we are editing or creating a new ad
  const [editingAd, setEditingAd] = useState(null);

  // Form data
  const [formData, setFormData] = useState({
    campaignId: "",
    adType: "",
    content: "",
    targetUrl: ""
  });

  // Validation or server-side error messages
  const [errors, setErrors] = useState({});

  useEffect(() => {
    fetchCampaigns();
    fetchAds();
  }, []);

  // 1) Fetch campaigns
  const fetchCampaigns = async () => {
    try {
      const token = localStorage.getItem("advertiserToken");
      const response = await fetch(`${API_BASE_URL}/advertiser/campaigns`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      if (!response.ok) {
        if (response.status === 401) {
          history.push("/advertiser-auth/login");
          return;
        }
        throw new Error("Failed to fetch campaigns");
      }

      const data = await response.json();
      setCampaigns(data || []);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching campaigns:", error);
      setIsLoading(false);
    }
  };

  // 2) Fetch ads
  const fetchAds = async () => {
    try {
      const token = localStorage.getItem("advertiserToken");
      const response = await fetch(`${API_BASE_URL}/advertiser/ads`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      if (!response.ok) {
        if (response.status === 401) {
          history.push("/advertiser-auth/login");
          return;
        }
        throw new Error("Failed to fetch ads");
      }

      const data = await response.json();
      setAds(data || []);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching ads:", error);
      setIsLoading(false);
    }
  };

  // Convert file to Base64
  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (readerEvent) => {
        resolve(readerEvent.target.result);
      };
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  };

  // -- CREATE & UPDATE Requests --

  // POST: Create a new Ad
  const createAdRequest = async (payload) => {
    try {
      const token = localStorage.getItem("advertiserToken");
      const response = await fetch(`${API_BASE_URL}/advertiser/ads`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(payload)
      });

      if (!response.ok) {
        if (response.status === 401) {
          history.push("/advertiser-auth/login");
          return null;
        } else if (response.status === 422) {
          const errorData = await response.json();
          return { validationErrors: errorData.errors };
        } else {
          throw new Error("Failed to create ad");
        }
      }
      return await response.json();
    } catch (error) {
      console.error("Error in createAdRequest:", error);
      return null;
    }
  };

  // PUT: Update an existing Ad
  const updateAdRequest = async (id, payload) => {
    try {
      const token = localStorage.getItem("advertiserToken");
      const response = await fetch(`${API_BASE_URL}/advertiser/ads/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(payload)
      });

      if (!response.ok) {
        if (response.status === 401) {
          history.push("/advertiser-auth/login");
          return null;
        } else if (response.status === 422) {
          const errorData = await response.json();
          return { validationErrors: errorData.errors };
        } else {
          throw new Error("Failed to update ad");
        }
      }
      return await response.json();
    } catch (error) {
      console.error("Error in updateAdRequest:", error);
      return null;
    }
  };

  // -- DELETE Request --

  const handleDeleteAd = async (adId) => {
    if (!window.confirm("Are you sure you want to delete this ad?")) {
      return;
    }
    try {
      const token = localStorage.getItem("advertiserToken");
      const response = await fetch(`${API_BASE_URL}/advertiser/ads/${adId}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      if (!response.ok) {
        if (response.status === 401) {
          history.push("/advertiser-auth/login");
          return;
        }
        throw new Error("Failed to delete ad");
      }
      toast.success("Ad deleted successfully!");
      fetchAds(); // Refresh ads list
    } catch (error) {
      console.error("Error deleting ad:", error);
      toast.error("Failed to delete ad.");
    }
  };

  // -- Modal & Form Logic --

  // Handle input changes
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Handle file selection for Banner/Video ads
  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (!file) return;
    try {
      const base64Data = await fileToBase64(file);
      setFormData({ ...formData, content: base64Data });
    } catch (error) {
      console.error("Error converting file to base64:", error);
      toast.error("Failed to process file");
    }
  };

  // Basic client-side validation
  const validateForm = () => {
    const newErrors = {};
    if (!formData.campaignId) {
      newErrors.campaignId = "Please select a campaign";
    }
    if (!formData.adType) {
      newErrors.adType = "Ad type is required";
    }
    if (!formData.targetUrl) {
      newErrors.targetUrl = "Target URL is required";
    }
    if (["Banner", "Video"].includes(formData.adType) && !formData.content) {
      newErrors.content = `Please upload a file for ${formData.adType} ad`;
    }
    if (formData.adType === "Text" && !formData.content) {
      newErrors.content = "Please provide text content for Text ad";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Handle form submission (CREATE or UPDATE)
  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors({});

    if (!validateForm()) return;

    const payload = {
      campaignId: formData.campaignId,
      adType: formData.adType,
      content: formData.content,
      targetUrl: formData.targetUrl
    };

    // Distinguish between creating a new ad or updating an existing ad
    if (!editingAd) {
      // Create new
      const result = await createAdRequest(payload);
      if (result === null) {
        toast.error("Failed to create ad. Please try again.");
        return;
      }
      if (result.validationErrors) {
        const fieldErrors = {};
        result.validationErrors.forEach((err) => {
          fieldErrors[err.path] = err.msg;
        });
        setErrors(fieldErrors);
        return;
      }
      toast.success("Ad created successfully!");
    } else {
      // Update existing
      const result = await updateAdRequest(editingAd._id, payload);
      if (result === null) {
        toast.error("Failed to update ad. Please try again.");
        return;
      }
      if (result.validationErrors) {
        const fieldErrors = {};
        result.validationErrors.forEach((err) => {
          fieldErrors[err.path] = err.msg;
        });
        setErrors(fieldErrors);
        return;
      }
      toast.success("Ad updated successfully!");
    }

    // Clear form & close modal
    setFormData({
      campaignId: "",
      adType: "",
      content: "",
      targetUrl: ""
    });
    setEditingAd(null);
    onClose();
    // Refresh ads
    fetchAds();
  };

  // -- Handlers for Opening Modal in Edit Mode or Create Mode --

  // Open modal to add a new ad
  const handleOpenCreateAd = () => {
    setEditingAd(null);
    setFormData({
      campaignId: "",
      adType: "",
      content: "",
      targetUrl: ""
    });
    setErrors({});
    onOpen();
  };

  // Open modal to edit an existing ad
  const handleOpenEditAd = (ad) => {
    setEditingAd(ad);
    setFormData({
      campaignId: ad.campaignId || "",
      adType: ad.adType || "",
      content: ad.content || "",
      targetUrl: ad.targetUrl || ""
    });
    setErrors({});
    onOpen();
  };

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Button colorScheme="blue" onClick={handleOpenCreateAd} alignSelf="flex-end" mb={4}>
        Create New Ad
      </Button>

      <ToastContainer />

      {/* Display existing ads in a table */}
      <Table variant="simple" mt={6} color='white'>
        <Thead>
          <Tr>
            <Th>Campaign ID</Th>
            <Th>Ad Type</Th>
            <Th>Content</Th>
            <Th>Target URL</Th>
            <Th>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {!isLoading &&
            ads.map((ad) => (
              <Tr key={ad._id}>
                <Td>{ad.campaignId.name}</Td>
                <Td>{ad.adType}</Td>
                <Td>
                  {ad.adType === "Banner" && ad.content ? (
                    <img
                      src={ad.content}
                      alt="Banner Ad"
                      style={{ width: "100px", height: "auto" }}
                    />
                  ) : ad.adType === "Video" && ad.content ? (
                    <video width="150" controls>
                      <source src={ad.content} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  ) : ad.adType === "Text" ? (
                    ad.content
                  ) : (
                    "N/A"
                  )}
                </Td>
                <Td>
                  <a
                    href={ad.targetUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "blue" }}
                  >
                    {ad.targetUrl}
                  </a>
                </Td>
                <Td>
                  <Button
                    colorScheme="blue"
                    mr={2}
                    onClick={() => handleOpenEditAd(ad)}
                  >
                    Edit
                  </Button>
                  <Button
                    colorScheme="red"
                    onClick={() => handleDeleteAd(ad._id)}
                  >
                    Delete
                  </Button>
                </Td>
              </Tr>
            ))}
        </Tbody>
      </Table>

      {/* Modal for creating/updating an Ad */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {editingAd ? "Edit Ad" : "Create a New Ad"}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form onSubmit={handleSubmit}>
              {/* Campaign Dropdown */}
              <FormControl mb={4} isInvalid={!!errors.campaignId}>
                <FormLabel>Campaign</FormLabel>
                <Select
                  name="campaignId"
                  placeholder="Select a campaign"
                  value={formData.campaignId}
                  onChange={handleChange}
                >
                  {campaigns.map((c) => (
                    <option key={c._id} value={c._id}>
                      {c.name}
                    </option>
                  ))}
                </Select>
                {errors.campaignId && (
                  <FormErrorMessage>{errors.campaignId}</FormErrorMessage>
                )}
              </FormControl>

              {/* Ad Type */}
              <FormControl mb={4} isInvalid={!!errors.adType}>
                <FormLabel>Ad Type</FormLabel>
                <Select
                  name="adType"
                  placeholder="Choose Ad Type"
                  value={formData.adType}
                  onChange={handleChange}
                >
                  <option value="Banner">Banner</option>
                  <option value="Video">Video</option>
                  <option value="Text">Text</option>
                </Select>
                {errors.adType && (
                  <FormErrorMessage>{errors.adType}</FormErrorMessage>
                )}
              </FormControl>

              {/* Content (file or text) */}
              {formData.adType === "Banner" || formData.adType === "Video" ? (
                <FormControl mb={4} isInvalid={!!errors.content}>
                  <FormLabel>
                    {formData.adType === "Banner"
                      ? "Banner Image"
                      : "Video File"}
                  </FormLabel>
                  <Input type="file" onChange={handleFileChange} />
                  {errors.content && (
                    <FormErrorMessage>{errors.content}</FormErrorMessage>
                  )}
                </FormControl>
              ) : formData.adType === "Text" ? (
                <FormControl mb={4} isInvalid={!!errors.content}>
                  <FormLabel>Text Content</FormLabel>
                  <Textarea
                    name="content"
                    value={formData.content}
                    onChange={handleChange}
                    placeholder="Enter text for your ad"
                  />
                  {errors.content && (
                    <FormErrorMessage>{errors.content}</FormErrorMessage>
                  )}
                </FormControl>
              ) : null}

              {/* Target URL */}
              <FormControl mb={4} isInvalid={!!errors.targetUrl}>
                <FormLabel>Target URL</FormLabel>
                <Input
                  name="targetUrl"
                  placeholder="https://example.com"
                  value={formData.targetUrl}
                  onChange={handleChange}
                />
                {errors.targetUrl && (
                  <FormErrorMessage>{errors.targetUrl}</FormErrorMessage>
                )}
              </FormControl>

              <Button colorScheme="blue" type="submit" isFullWidth>
                {editingAd ? "Update" : "Create"}
              </Button>
            </form>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" variant="outline" onClick={onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
}



import Dashboard from "views/Advertiser/Dashboard/Dashboard";
import Tables from "views/Dashboard/Tables.js";
import Billing from "views/Dashboard/Billing.js";
import RTLPage from "views/RTL/RTLPage.js";
import Profile from "views/Dashboard/Profile.js";
import SignIn from "views/Pages/SignIn.js";

import AdvertiserLogin from "views/Pages/AdvertiserLogin";
import AdvertiserRegister from "views/Pages/AdvertiserRegister";
import SignUp from "views/Pages/SignUp.js";

// add managemenet/ingame adds module

   
import CharactersModule from "views/adsManagement/inGame/Characters";
import Campaigns from "views/Advertiser/Campaigns/Campaigns";
import Payments from "views/Advertiser/Payments/Payments";
import Settings from "views/Advertiser/Settings/Settings";

import {
  HomeIcon,
  StatsIcon,
  CreditIcon,
  PersonIcon,
  DocumentIcon,
  RocketIcon,
  SupportIcon,
  CharactersIcon,
} from "components/Icons/Icons";

import ClientCharacterList from "views/Client/ClientCharacterList";
import ClientCharacterDetails from "views/Client/ClientCharacterDetails/ClientCharacterDetails";
import Ads from "views/Advertiser/AdvertiserAds/Ads";

var advertiserRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: <HomeIcon color='inherit' />,
    component: Dashboard,
    layout: "/advertiser",
  },

  {
    path: "/campaigns",
    name: "Campaigns",
    rtlName: "لوحة القيادة",
    icon: <HomeIcon color='inherit' />,
    component: Campaigns,
    layout: "/advertiser",
  },

  
  {
    path: "/payments",
    name: "Payments",
    rtlName: "لوحة القيادة",
    icon: <HomeIcon color='inherit' />,
    component: Payments,
    layout: "/advertiser",
  },

  {
    path: "/settings",
    name: "Settings",
    rtlName: "لوحة القيادة",
    icon: <HomeIcon color='inherit' />,
    component: Settings,
    layout: "/advertiser",
  },

  {
    path: "/ads",
    name: "Ads",
    rtlName: "لوحة القيادة",
    icon: <HomeIcon color='inherit' />,
    component: Ads,
    layout: "/advertiser",
  },
 
 
  {
    path: "/login",
    name: "Login",
    rtlName: "لوحة القيادة",
    icon: <DocumentIcon color='inherit' />,
    component: AdvertiserLogin,
    layout: "/advertiser-auth",
  },
  {
    path: "/register",
    name: "Register",
    rtlName: "لوحة القيادة",
    icon: <DocumentIcon color='inherit' />,
    component: AdvertiserRegister,
    layout: "/advertiser-auth",
  },
  // {
  //   name: "ACCOUNT PAGES",
  //   category: "account",
  //   rtlName: "صفحات",
  //   state: "pageCollapse",
  //   views: [
  //     {
  //       path: "/profile",
  //       name: "Profile",
  //       rtlName: "لوحة القيادة",
  //       icon: <PersonIcon color='inherit' />,
  //       secondaryNavbar: true,
  //       component: Profile,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/signin",
  //       name: "Sign In",
  //       rtlName: "لوحة القيادة",
  //       icon: <DocumentIcon color='inherit' />,
  //       component: SignIn,
  //       layout: "/auth",
  //     },
  //     {
  //       path: "/signup",
  //       name: "Sign Up",
  //       rtlName: "لوحة القيادة",
  //       icon: <RocketIcon color='inherit' />,
  //       secondaryNavbar: true,
  //       component: SignUp,
  //       layout: "/auth",
  //     },
  //   ],
  // },
];
export default advertiserRoutes;

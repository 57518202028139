
import React, { useState } from "react";
import {
  Box,
  Flex,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Link,
  Switch,
  Text,
  Icon,
  DarkMode,
  FormErrorMessage,
} from "@chakra-ui/react";
import { FaApple, FaFacebook, FaGoogle } from "react-icons/fa";
import GradientBorder from "components/GradientBorder/GradientBorder";
import AdvertaiserAuthFooter from "components/Footer/AdvertaiserAuthFooter";
import signUpImage from "assets/img/signUpImage.png";
import { useHistory } from "react-router-dom";
import { API_BASE_URL } from "config";

function AdvertiserRegister() {
  const titleColor = "white";
  const textColor = "gray.400";
  const history = useHistory();

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    phone_number: "",
    company_name: "",
    company_origin: "",
    email: "",
    password: "",
    confirm_password: "",
  });

  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSignUp = async (e) => {
    e.preventDefault();
    setErrors({});

    if (formData.password !== formData.confirm_password) {
      setErrors({ confirm_password: "Confirm password should match the password" });
      return;
    }

    try {
      const response = await fetch(`${API_BASE_URL}/advertiser/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        const data = await response.json();
        localStorage.setItem('clientToken', data.token); // Store the token
        history.push('/client/dashboard'); // Redirect to dashboard
      } else {
        const errorData = await response.json();
        const errorMessages = {};
        errorData.errors.forEach((error) => {
          errorMessages[error.path] = error.msg;
        });
        setErrors(errorMessages);
      }
    } catch (error) {
      console.error("Registration error:", error);
      
      alert("An error occurred during registration.");
    }
  };

  return (
    <Flex position='relative' overflow={{ lg: "hidden" }}>
      <Flex
        flexDirection='column'
        h={{ sm: "initial", md: "unset" }}
        w={{ base: "90%" }}
        maxW='1450px'
        mx='auto'
        justifyContent='space-between'
        pt={{ sm: "100px", md: "0px" }}
        me={{ base: "auto", lg: "50px", xl: "auto" }}>
        <Flex
          alignItems='center'
          justifyContent='start'
          style={{ userSelect: "none" }}
          flexDirection='column'
          mx={{ base: "auto", lg: "unset" }}
          ms={{ base: "auto", lg: "auto" }}
          mb='50px'
          w={{ base: "100%", md: "50%", lg: "42%" }}>
          <GradientBorder p='2px' me={{ base: "none", lg: "30px", xl: "none" }}>
            <Flex
              background='transparent'
              borderRadius='30px'
              direction='column'
              p='40px'
              minW={{ base: "unset", md: "430px", xl: "450px" }}
              w='100%'
              mx={{ base: "0px" }}
              bg={{
                base: "rgb(19,21,56)",
              }}>
              <Text
                fontSize='xl'
                color={textColor}
                fontWeight='bold'
                textAlign='center'
                mb='22px'>
                Register With
              </Text>
              <HStack spacing='15px' justify='center' mb='22px'>
                <GradientBorder borderRadius='15px'>
                  <Flex
                    _hover={{ filter: "brightness(120%)" }}
                    transition='all .25s ease'
                    cursor='pointer'
                    justify='center'
                    align='center'
                    bg='rgb(19,21,54)'
                    w='71px'
                    h='71px'
                    borderRadius='15px'>
                    <Link href='#'>
                      <Icon
                        color={titleColor}
                        as={FaFacebook}
                        w='30px'
                        h='30px'
                        _hover={{ filter: "brightness(120%)" }}
                      />
                    </Link>
                  </Flex>
                </GradientBorder>
                <GradientBorder borderRadius='15px'>
                  <Flex
                    _hover={{ filter: "brightness(120%)" }}
                    transition='all .25s ease'
                    cursor='pointer'
                    justify='center'
                    align='center'
                    bg='rgb(19,21,54)'
                    w='71px'
                    h='71px'
                    borderRadius='15px'>
                    <Link href='#'>
                      <Icon
                        color={titleColor}
                        as={FaApple}
                        w='30px'
                        h='30px'
                        _hover={{ filter: "brightness(120%)" }}
                      />
                    </Link>
                  </Flex>
                </GradientBorder>
                <GradientBorder borderRadius='15px'>
                  <Flex
                    _hover={{ filter: "brightness(120%)" }}
                    transition='all .25s ease'
                    cursor='pointer'
                    justify='center'
                    align='center'
                    bg='rgb(19,21,54)'
                    w='71px'
                    h='71px'
                    borderRadius='15px'>
                    <Link href='#'>
                      <Icon
                        color={titleColor}
                        as={FaGoogle}
                        w='30px'
                        h='30px'
                        _hover={{ filter: "brightness(120%)" }}
                      />
                    </Link>
                  </Flex>
                </GradientBorder>
              </HStack>
              <Text
                fontSize='lg'
                color='gray.400'
                fontWeight='bold'
                textAlign='center'
                mb='22px'>
                or
              </Text>
              {/* Input fields for registration */}
              {/* Input Fields Start */}
              <HStack spacing='24px' mb='24px'>
                <FormControl isInvalid={!!errors.first_name}>
                  <FormLabel color={titleColor} ms='4px' fontSize='sm' fontWeight='normal'>
                    First Name
                  </FormLabel>
                  <GradientBorder h='50px' w='fit-content' borderRadius='20px'>
                    <Input
                      name='first_name'
                      value={formData.first_name}
                      onChange={handleInputChange}
                      color={titleColor}
                      bg={{
                        base: "rgb(19,21,54)",
                      }}
                      border='transparent'
                      borderRadius='20px'
                      fontSize='sm'
                      size='lg'
                      w={{ base: "100%", md: "346px" }}
                      maxW='100%'
                      h='46px'
                      type='text'
                      placeholder='Your first name'
                    />
                  </GradientBorder>
                  {errors.first_name && <FormErrorMessage>{errors.first_name}</FormErrorMessage>}
                </FormControl>

                <FormControl isInvalid={!!errors.last_name}>
                  <FormLabel color={titleColor} ms='4px' fontSize='sm' fontWeight='normal'>
                    Last Name
                  </FormLabel>
                  <GradientBorder h='50px' w='fit-content' borderRadius='20px'>
                    <Input
                      name='last_name'
                      value={formData.last_name}
                      onChange={handleInputChange}
                      color={titleColor}
                      bg={{
                        base: "rgb(19,21,54)",
                      }}
                      border='transparent'
                      borderRadius='20px'
                      fontSize='sm'
                      size='lg'
                      w={{ base: "100%", md: "346px" }}
                      maxW='100%'
                      h='46px'
                      type='text'
                      placeholder='Your last name'
                    />
                  </GradientBorder>
                  {errors.last_name && <FormErrorMessage>{errors.last_name}</FormErrorMessage>}
                </FormControl>
              </HStack>

              <HStack spacing="24px" mb="24px">
                <FormControl isInvalid={!!errors.phone_number}>
                  <FormLabel color={titleColor} ms='4px' fontSize='sm' fontWeight='normal'>
                    Phone Number
                  </FormLabel>
                  <GradientBorder mb='24px' h='50px' w={{ base: "100%", lg: "fit-content" }} borderRadius='20px'>
                    <Input
                      name='phone_number'
                      value={formData.phone_number}
                      onChange={handleInputChange}
                      color={titleColor}
                      bg={{
                        base: "rgb(19,21,54)",
                      }}
                      border='transparent'
                      borderRadius='20px'
                      fontSize='sm'
                      size='lg'
                      w={{ base: "100%", md: "346px" }}
                      maxW='100%'
                      h='46px'
                      type='text'
                      placeholder='Your phone number'
                    />
                  </GradientBorder>
                  {errors.phone_number && <FormErrorMessage>{errors.phone_number}</FormErrorMessage>}
                </FormControl>

                <FormControl isInvalid={!!errors.company_name}>
                  <FormLabel color={titleColor} ms='4px' fontSize='sm' fontWeight='normal'>
                    Company Name
                  </FormLabel>
                  <GradientBorder mb='24px' h='50px' w={{ base: "100%", lg: "fit-content" }} borderRadius='20px'>
                    <Input
                      name='company_name'
                      value={formData.company_name}
                      onChange={handleInputChange}
                      color={titleColor}
                      bg={{
                        base: "rgb(19,21,54)",
                      }}
                      border='transparent'
                      borderRadius='20px'
                      fontSize='sm'
                      size='lg'
                      w={{ base: "100%", md: "346px" }}
                      maxW='100%'
                      h='46px'
                      type='text'
                      placeholder='Your company name'
                    />
                  </GradientBorder>
                  {errors.company_name && <FormErrorMessage>{errors.company_name}</FormErrorMessage>}
                </FormControl>

              </HStack>

              <HStack spacing='24px' mb='24px'>

                <FormControl isInvalid={!!errors.company_origin}>
                  <FormLabel color={titleColor} ms='4px' fontSize='sm' fontWeight='normal'>
                    Company Origin
                  </FormLabel>
                  <GradientBorder mb='24px' h='50px' w={{ base: "100%", lg: "fit-content" }} borderRadius='20px'>
                    <Input
                      name='company_origin'
                      value={formData.company_origin}
                      onChange={handleInputChange}
                      color={titleColor}
                      bg={{
                        base: "rgb(19,21,54)",
                      }}
                      border='transparent'
                      borderRadius='20px'
                      fontSize='sm'
                      size='lg'
                      w={{ base: "100%", md: "346px" }}
                      maxW='100%'
                      h='46px'
                      type='text'
                      placeholder='Company origin'
                    />
                  </GradientBorder>
                  {errors.company_origin && <FormErrorMessage>{errors.company_origin}</FormErrorMessage>}
                </FormControl>

                <FormControl isInvalid={!!errors.email}>
                  <FormLabel color={titleColor} ms='4px' fontSize='sm' fontWeight='normal'>
                    Email
                  </FormLabel>
                  <GradientBorder mb='24px' h='50px' w={{ base: "100%", lg: "fit-content" }} borderRadius='20px'>
                    <Input
                      name='email'
                      value={formData.email}
                      onChange={handleInputChange}
                      color={titleColor}
                      bg={{
                        base: "rgb(19,21,54)",
                      }}
                      border='transparent'
                      borderRadius='20px'
                      fontSize='sm'
                      size='lg'
                      w={{ base: "100%", md: "346px" }}
                      maxW='100%'
                      h='46px'
                      type='email'
                      placeholder='Your email address'
                    />
                  </GradientBorder>
                  {errors.email && <FormErrorMessage>{errors.email}</FormErrorMessage>}
                </FormControl>

              </HStack>

              <HStack spacing='24px' mb='24px'>
                <FormControl isInvalid={!!errors.password}>
                  <FormLabel color={titleColor} ms='4px' fontSize='sm' fontWeight='normal'>
                    Password
                  </FormLabel>
                  <GradientBorder h='50px' w='fit-content' borderRadius='20px'>
                    <Input
                      name='password'
                      value={formData.password}
                      onChange={handleInputChange}
                      color={titleColor}
                      bg={{
                        base: "rgb(19,21,54)",
                      }}
                      border='transparent'
                      borderRadius='20px'
                      fontSize='sm'
                      size='lg'
                      w={{ base: "100%", md: "346px" }}
                      maxW='100%'
                      h='46px'
                      type='password'
                      placeholder='Your password'
                    />
                  </GradientBorder>
                  {errors.password && <FormErrorMessage>{errors.password}</FormErrorMessage>}
                </FormControl>

                <FormControl isInvalid={!!errors.confirm_password}>
                  <FormLabel color={titleColor} ms='4px' fontSize='sm' fontWeight='normal'>
                    Confirm Password
                  </FormLabel>
                  <GradientBorder h='50px' w='fit-content' borderRadius='20px'>
                    <Input
                      name='confirm_password'
                      value={formData.confirm_password}
                      onChange={handleInputChange}
                      color={titleColor}
                      bg={{
                        base: "rgb(19,21,54)",
                      }}
                      border='transparent'
                      borderRadius='20px'
                      fontSize='sm'
                      size='lg'
                      w={{ base: "100%", md: "346px" }}
                      maxW='100%'
                      h='46px'
                      type='password'
                      placeholder='Confirm your password'
                    />
                  </GradientBorder>
                  {errors.confirm_password && <FormErrorMessage>{errors.confirm_password}</FormErrorMessage>}
                </FormControl>
              </HStack>
              {/* Input Fields End */}

              {/* Repeat similar FormControl for other fields */}
              {/* Sign Up Button */}
              <Flex justifyContent='center' alignItems='center' w='100%'>
              <Button
                variant='brand'
                fontSize='10px'
                type='submit'
                w='100%'
                maxW='350px'
                h='45'
                mb='20px'
                mt='20px'
                onClick={handleSignUp}>
                SIGN UP
              </Button>
            </Flex>

            </Flex>
          </GradientBorder>
        </Flex>
        <Box
          w={{ base: "335px", md: "450px" }}
          mx={{ base: "auto", lg: "unset" }}
          ms={{ base: "auto", lg: "auto" }}
          mb='90px'>
          <AdvertaiserAuthFooter/>
        </Box>
        <Box
          display={{ base: "none", lg: "block" }}
          overflowX='hidden'
          h='1300px'
          maxW={{ md: "50vw", lg: "48vw" }}
          w='960px'
          position='absolute'
          left='0px'>
          <Box
            bgImage={signUpImage}
            w='100%'
            h='1300px'
            bgSize='cover'
            bgPosition='50%'
            position='absolute'
            display='flex'
            flexDirection='column'
            justifyContent='center'
            alignItems='center'>
            <Text
              textAlign='center'
              color='white'
              letterSpacing='8px'
              fontSize='20px'
              fontWeight='500'>
              INSPIRED BY THE FUTURE:
            </Text>
            <Text
              textAlign='center'
              color='transparent'
              letterSpacing='8px'
              fontSize='36px'
              fontWeight='bold'
              bgClip='text !important'
              bg='linear-gradient(94.56deg, #FFFFFF 79.99%, #21242F 102.65%)'>
              THE VISION UI DASHBOARD
            </Text>
          </Box>
        </Box>
      </Flex>
    </Flex>
  );
}

export default AdvertiserRegister;

import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Select,
  Spinner,
} from "@chakra-ui/react";

import { API_BASE_URL } from "config"; // Ensure this points to your backend
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

function Earnings() {
  const [earnings, setEarnings] = useState([]);
  const [slots, setSlots] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState(""); // Selected Slot ID
  const [isLoading, setIsLoading] = useState(false);
  const [isSlotsLoading, setIsSlotsLoading] = useState(true);

  // Fetch all slots for the dropdown
  const fetchSlots = async () => {
    try {
      const token = localStorage.getItem("publisherToken");
      const response = await fetch(`${API_BASE_URL}/publisher/ad-slots`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch ad slots");
      }

      const data = await response.json();
      setSlots(data || []);
    } catch (error) {
      console.error("Error fetching ad slots:", error);
      toast.error("Failed to load ad slots.");
    } finally {
      setIsSlotsLoading(false);
    }
  };

  // Fetch earnings based on selected slot
  const fetchEarnings = async (slotId) => {
    try {
      setIsLoading(true);
      const token = localStorage.getItem("publisherToken");
      const query = slotId ? `?adSlotId=${slotId}` : "";
      const response = await fetch(`${API_BASE_URL}/publisher/earnings${query}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch earnings");
      }

      const data = await response.json();
      setEarnings(data.earnings || []); // Access earnings array inside the response
    } catch (error) {
      console.error("Error fetching earnings:", error);
      toast.error("Failed to load earnings data.");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchSlots(); // Fetch slots for the dropdown
    fetchEarnings(); // Fetch all earnings initially
  }, []);

  // Handle slot selection
  const handleSlotChange = (e) => {
    const slotId = e.target.value;
    setSelectedSlot(slotId);
    fetchEarnings(slotId); // Fetch filtered earnings data
  };

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Flex justifyContent="space-between" mb="4">
        {isSlotsLoading ? (
          <Spinner size="md" color="blue.500" />
        ) : (
          <Select
            placeholder="Select Ad Slot"
            value={selectedSlot}
            onChange={handleSlotChange}
            maxW="300px"
          >
            {slots.map((slot) => (
              <option key={slot._id} value={slot._id}>
                {slot.name}
              </option>
            ))}
          </Select>
        )}
      </Flex>

      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="lg" fontWeight="bold" color="white">
            Earnings List
          </Text>
        </CardHeader>
        <CardBody>
          <Table variant="simple" color="white">
            <Thead>
              <Tr>
                <Th color="white">Ad Slot Name</Th>
                <Th color="white">Date</Th>
                <Th color="white">Revenue</Th>
                <Th color="white">Impressions</Th>
                <Th color="white">Clicks</Th>
              </Tr>
            </Thead>
            <Tbody>
              {!isLoading &&
                earnings.map((earning) => (
                  <Tr key={earning._id}>
                    <Td>{earning.adSlotId?.name || "Unknown Slot"}</Td>
                    <Td>{earning.date ? earning.date.substring(0, 10) : ""}</Td>
                    <Td>{earning.revenue.toFixed(2)}</Td>
                    <Td>{earning.impressions}</Td>
                    <Td>{earning.clicks}</Td>
                  </Tr>
                ))}
              {isLoading && (
                <Tr>
                  <Td colSpan="5" textAlign="center">
                    <Spinner size="md" color="blue.500" />
                  </Td>
                </Tr>
              )}
            </Tbody>
          </Table>
        </CardBody>
      </Card>
      <ToastContainer />
    </Flex>
  );
}

export default Earnings;

import React, { useEffect } from 'react';

// import 'assets/template_assets/css/button.min.css';
// import 'assets/template_assets/css/fonts.min.css';
// import 'assets/template_assets/css/home-banner.min.css';
// import 'assets/template_assets/css/index.css';
// import 'assets/template_assets/css/main.css';
// import 'assets/template_assets/css/newsletter_form_module.css';
// import 'assets/template_assets/css/style.css';
// import 'assets/template_assets/css/testimonials-slider.min.css';
// import 'assets/template_assets/css/theme-overrides.min.css';
// import 'assets/template_assets/css/two-column-content.min.css';
import FrontHeader from './FrontHeader';
import Contact from './Contact';


export default function Home() {

  useEffect(() => {
    // Dynamically create and load CSS files
    const cssFiles = [
        require('assets/template_assets/css/button.min.css'),
        require('assets/template_assets/css/fonts.min.css'),
        require('assets/template_assets/css/home-banner.min.css'),
        require('assets/template_assets/css/index.css'),
        require('assets/template_assets/css/main.css'),
        require('assets/template_assets/css/newsletter_form_module.css'),
        require('assets/template_assets/css/style.css'),
        require('assets/template_assets/css/testimonials-slider.min.css'),
        require('assets/template_assets/css/theme-overrides.min.css'),
        require('assets/template_assets/css/two-column-content.min.css'),
    ];

    const linkElements = cssFiles.map((file) => {
      const link = document.createElement('link');
      link.rel = 'stylesheet';
      link.href = file;
      document.head.appendChild(link);
      return link;
    });

    // Cleanup: Remove CSS files on component unmount
    return () => {
      linkElements.forEach((link) => document.head.removeChild(link));
    };
  }, []);
  return (


 <div className="">
  
    <FrontHeader />
  
  <div className="banner-section home-banner">
    <div className="banner-area">
      <div className="row-fluid-wrapper">
        <div className="row-fluid">
          <div className="span12 widget-span widget-type-cell " style={{}} data-widget-type="cell" data-x={0} data-w={12}>
            <div className="row-fluid-wrapper row-depth-1 row-number-1 dnd-section DND_banner-row-0-padding DND_banner-row-0-force-full-width-section">
              <div className="row-fluid ">
                <div className="span12 widget-span widget-type-cell dnd-column" style={{}} data-widget-type="cell" data-x={0} data-w={12}>
                  <div className="row-fluid-wrapper row-depth-1 row-number-2 dnd-row">
                    <div className="row-fluid ">
                      <div className="span12 widget-span widget-type-custom_widget widget_1636703820351-hidden dnd-module" style={{}} data-widget-type="custom_widget" data-x={0} data-w={12}>
                        <div id="hs_cos_wrapper_widget_1636703820351" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-post_filter" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module">
                          <span id="hs_cos_wrapper_widget_1636703820351_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_post_filter" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="post_filter">
                            <div className="block">
                              <h3 />
                              <div className="widget-module">
                                <ul>
                                  <li>
                                    <a href="#">Advertisers <span className="filter-link-count" dir="ltr">(12)</span></a>
                                  </li>
                                  <li>
                                    <a href="https://www.portal.seiky.in/">Seiky Asks <span className="filter-link-count" dir="ltr">(7)</span></a>
                                  </li>
                                  <li>
                                    <a href="https://www.portal.seiky.in/">Seiky Attends <span className="filter-link-count" dir="ltr">(12)</span></a>
                                  </li>
                                  <li>
                                    <a href="https://www.portal.seiky.in/">Seiky Talks <span className="filter-link-count" dir="ltr">(3)</span></a>
                                  </li>
                                  <li>
                                    <a href="https://www.portal.seiky.in/">Seiky Toolbox <span className="filter-link-count" dir="ltr">(1)</span></a>
                                  </li>
                                  <li style={{display: 'none'}}>
                                    <a href="#">Blog <span className="filter-link-count" dir="ltr">(19)</span></a>
                                  </li>
                                  <li style={{display: 'none'}}>
                                    <a href="#">Console <span className="filter-link-count" dir="ltr">(1)</span></a>
                                  </li>
                                  <li style={{display: 'none'}}>
                                    <a href="#">Developers <span className="filter-link-count" dir="ltr">(7)</span></a>
                                  </li>
                                  <li style={{display: 'none'}}>
                                    <a href="#">Fyber <span className="filter-link-count" dir="ltr">(1)</span></a>
                                  </li>
                                  <li style={{display: 'none'}}>
                                    <a href="#">Game Developer Interviews <span className="filter-link-count" dir="ltr">(8)</span></a>
                                  </li>
                                  <li style={{display: 'none'}}>
                                    <a href="#">Gaming <span className="filter-link-count" dir="ltr">(7)</span></a>
                                  </li>
                                  <li style={{display: 'none'}}>
                                    <a href="https://www.portal.seiky.in/blog/tag/gaming-digest">Gaming Digest <span className="filter-link-count" dir="ltr">(9)</span></a>
                                  </li>
                                  <li style={{display: 'none'}}>
                                    <a href="https://www.portal.seiky.in/blog/tag/getting-into-gaming">Getting into gaming <span className="filter-link-count" dir="ltr">(14)</span></a>
                                  </li>
                                  <li style={{display: 'none'}}>
                                    <a href="https://www.portal.seiky.in/blog/tag/in-game-advertising-guides">In-Game Advertising Guides <span className="filter-link-count" dir="ltr">(4)</span></a>
                                  </li>
                                  <li style={{display: 'none'}}>
                                    <a href="https://www.portal.seiky.in/blog/tag/insights">Insights <span className="filter-link-count" dir="ltr">(6)</span></a>
                                  </li>
                                  <li style={{display: 'none'}}>
                                    <a href="https://www.portal.seiky.in/blog/tag/itamar-on">Itamar On... <span className="filter-link-count" dir="ltr">(1)</span></a>
                                  </li>
                                  <li style={{display: 'none'}}>
                                    <a href="https://www.portal.seiky.in/blog/tag/metaverse">Metaverse <span className="filter-link-count" dir="ltr">(1)</span></a>
                                  </li>
                                  <li style={{display: 'none'}}>
                                    <a href="https://www.portal.seiky.in/blog/tag/publishers">Publishers <span className="filter-link-count" dir="ltr">(2)</span></a>
                                  </li>
                                  <li style={{display: 'none'}}>
                                    <a href="https://www.portal.seiky.in/blog/tag/webinars">Webinars <span className="filter-link-count" dir="ltr">(1)</span></a>
                                  </li>
                                </ul>
                                <a className="filter-expand-link" href="#">See all</a>
                              </div>
                            </div>
                          </span>
                        </div>
                      </div>{/*end widget-span */}
                    </div>{/*end row*/}
                  </div>{/*end row-wrapper */}
                  <div className="row-fluid-wrapper row-depth-1 row-number-3 dnd-row">
                    <div className="row-fluid ">
                      <div className="span12 widget-span widget-type-custom_widget dnd-module" style={{}} data-widget-type="custom_widget" data-x={0} data-w={12}>
                        <div id="hs_cos_wrapper_DND_banner-module-1" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module">
                          <div className="home_banner hero cm_banner DND_banner-module-1">
                            {/*   <video class="hero__video" poster="">
  </video> */}
                            <video className="lazy hero__video" autoPlay muted loop playsInline>
  <source src={require('../../assets/template_assets/videos/SEIKY_Website_Intro.mp4')} type="video/mp4" />

</video>

                            <div className="hero__container content-wrapper">
                              <div className="hero__content-wrapper">
                                <h1 className="hero__title">Revolutionize In-Game Advertising with Seiky</h1>
                                <div className="hero__content">
                                  <center>
                                    <p>Empowering developers and advertisers with a seamless, cross-platform ad solution for games and beyond.</p>
                                  </center>
                                </div>
                              </div>
                            </div>
                            <div className="overlaybg">
                            </div>
                            <style dangerouslySetInnerHTML={{__html: "\n\n                              " }} />
                          </div>
                        </div>{/*end widget-span */}
                      </div>{/*end row*/}
                    </div>{/*end row-wrapper */}
                  </div>{/*end widget-span */}
                </div>{/*end row*/}
              </div>{/*end row-wrapper */}
            </div>{/*end widget-span */}
          </div>
        </div>
      </div>
    </div>
    <main id="main-content" className="body-container-wrapper hm-page">
      <div className="hm-tp-sec">
        <div className="row-fluid-wrapper">
          <div className="row-fluid">
            <div className="span12 widget-span widget-type-cell " style={{}} data-widget-type="cell" data-x={0} data-w={12}>
              <div className="row-fluid-wrapper row-depth-1 row-number-1 top_area-row-0-background-layers top_area-row-0-background-image dnd-section">
                <div className="row-fluid ">
                  <div className="span12 widget-span widget-type-cell dnd-column" style={{}} data-widget-type="cell" data-x={0} data-w={12}>
                    <div className="row-fluid-wrapper row-depth-1 row-number-2 dnd-row">
                      <div className="row-fluid ">
                        <div className="span12 widget-span widget-type-custom_widget module_1628146879392-hidden dnd-module" style={{}} data-widget-type="custom_widget" data-x={0} data-w={12}>
                          <div id="hs_cos_wrapper_module_1628146879392" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-rich_text">
                            <span id="hs_cos_wrapper_module_1628146879392_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_rich_text">
                              <h1 style={{color: '#fff'}}>A Seamless Experience for Advertisers and Developers</h1>
                              <p style={{color: '#fff'}}>create a seamless and engaging in-game advertising experience that benefits developers, advertisers.</p>
                            </span>
                          </div>
                        </div>{/*end widget-span */}
                      </div>{/*end row*/}
                    </div>{/*end row-wrapper */}
                    <div className="row-fluid-wrapper row-depth-1 row-number-3 dnd-row">
                      <div className="row-fluid ">
                        <div className="span12 widget-span widget-type-custom_widget widget_1628146839328-hidden dnd-module" style={{}} data-widget-type="custom_widget" data-x={0} data-w={12}>
                          <div id="hs_cos_wrapper_widget_1628146839328" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-rich_text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module"><span id="hs_cos_wrapper_widget_1628146839328_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_rich_text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="rich_text">
                              <h1>THE WORLD’S <br />MOST ADVANCED <br />IN-GAME ADVERTISING <br />PLATFORM&nbsp;</h1>
                              <p><span>Seiky helps advertisers reach gamers via intrinsic in-game ads that complement&nbsp;</span><br /><span>the experience, and developers monetize their games while respecting gameplay.</span></p>
                            </span></div>
                        </div>{/*end widget-span */}
                      </div>{/*end row*/}
                    </div>{/*end row-wrapper */}
                    <div className="row-fluid-wrapper row-depth-1 row-number-4 dnd-row">
                      <div className="row-fluid ">
                        <div className="span12 widget-span widget-type-custom_widget dnd-module" style={{}} data-widget-type="custom_widget" data-x={0} data-w={12}>
                          <div id="hs_cos_wrapper_widget_1628161129657" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module">
                            <div className="button-module">
                              <a href="#" className="button-module-link">
                                <div className="button-module-link-text" style={{backgroundColor: '00ffff',fontWeight:'bold'}}>
                                  I’M AN ADVERTISER
                                  <br />
                                  Get Started &gt;
                                </div>
                              </a>
                              <a href="https://www.portal.seiky.in/developers" className="button-module-link">
                                <div className="button-module-link-text" style={{fontWeight:'bold'}}>
                                  I’M AN PUBLISHER
                                  <br />
                                  Get Started &gt;
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>{/*end widget-span */}
                      </div>{/*end row*/}
                    </div>{/*end row-wrapper */}
                  </div>{/*end widget-span */}
                </div>{/*end row*/}
              </div>{/*end row-wrapper */}
            </div>{/*end widget-span */}
          </div>
        </div>
      </div>
      <div className="hm-mid-sec">
        <div className="row-fluid-wrapper">
          <div className="row-fluid">
            <div className="span12 widget-span widget-type-cell " style={{}} data-widget-type="cell" data-x={0} data-w={12}>
              <div className="row-fluid-wrapper row-depth-1 row-number-1 dnd-section middle_area-row-0-padding middle_area-row-0-force-full-width-section">
                <div className="row-fluid ">
                  <div className="span12 widget-span widget-type-cell cell_16281492845812-padding dnd-column" style={{}} data-widget-type="cell" data-x={0} data-w={12}>
                    <div className="row-fluid-wrapper row-depth-1 row-number-2 dnd-row">
                      <div className="row-fluid ">
                        <div className="span12 widget-span widget-type-custom_widget dnd-module" style={{}} data-widget-type="custom_widget" data-x={0} data-w={12}>
                        </div>{/*end widget-span */}
                      </div>{/*end row*/}
                    </div>{/*end row-wrapper */}
                  </div>{/*end widget-span */}
                </div>{/*end row*/}
              </div>{/*end row-wrapper */}
              <div className="row-fluid-wrapper row-depth-1 row-number-3 dnd-section middle_area-row-1-padding middle_area-row-1-force-full-width-section">
                <div className="row-fluid ">
                  <div className="span12 widget-span widget-type-cell cell_16281506543652-padding dnd-column" style={{backgroundImage: `url(${require('../../assets/template_assets/images/about-sieky.png')})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: '-200px'}}>
                    <div className="row-fluid-wrapper row-depth-1 row-number-4 dnd-row">
                      <div className="row-fluid ">
                        <div className="span12 widget-span widget-type-custom_widget dnd-module" style={{}} data-widget-type="custom_widget" data-x={0} data-w={12}>
                          <div id="hs_cos_wrapper_widget_1628150654006" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module">
                            <div className="site-content__section section--bg cross-platforms widget_1628150654006">
                              <div className="cross-platforms__bg-wrapper section__bg-wrapper" style={{background: '#01153a'}}>
                              </div>
                              <div className="cross-platforms__container content-wrapper">
                                <div className="cross-platforms__content-wrapper">
                                  <h2 className="cross-platforms__title">
                                    <div className="desktop-only">A SOLUTION <br /> FOR ALL THE PLATFORMS </div>
                                  </h2>
                                  <div className="cross-platforms__platforms platforms" style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                    <div className>
                                      <a href="#">
                                        <div className="platforms__item platform">
                                          <span className="platform__icon typecm_svg">
                                            <div style={{backgroundColor: '#fff', display: 'inline-block', padding: 10, borderRadius: 20}}>
                                              <img src={require('../../assets/template_assets/images/mobile.png')} alt="mobile" width={125} height={125} loading="lazy" />
                                            </div>
                                          </span>
                                          <span className="platform__name" style={{fontWeight:'bold'}}> MOBILE</span>
                                        </div>
                                      </a>
                                      <a href="#">
                                        <div className="platforms__item platform">
                                          <span className="platform__icon typecm_svg">
                                            <div style={{backgroundColor: '#fff', display: 'inline-block', padding: 10, borderRadius: 20}}>
                                              <img src={require('../../assets/template_assets/images/desktop.png')} alt="pc" width={125} height={125} loading="lazy" style={{width: 125}} />
                                            </div>
                                          </span>
                                          <span className="platform__name" style={{fontWeight:'bold'}}> PC </span>
                                        </div>
                                      </a>
                                      <a href="#">
                                        <div className="platforms__item platform">
                                          <span className="platform__icon typecm_svg">
                                            <div style={{backgroundColor: '#fff', display: 'inline-block', padding: 10, borderRadius: 20}}>
                                              <img src={require('../../assets/template_assets/images/web.png')} alt="CONSOLE" width={112} height={112} loading="lazy" style={{width: 112}} />
                                            </div>
                                          </span>
                                          <span className="platform__name" style={{fontWeight:'bold'}}> WEB</span>
                                        </div>
                                      </a>
                                    </div>
                                    {/* <div style={{position: 'relative', left: '35%'}}><img src={require('../../assets/template_assets/images/WebPortal.png')} style={{width: '100%', height: '50vh'}} /></div> */}
                                    <div style={{ position: 'relative', left: '35%' }}>
  {/* Wrapper to position the text over the image */}
  <div style={{ position: 'relative', display: 'inline-block' }}>
    {/* Image */}
    <img 
      src={require('../../assets/template_assets/images/WebPortal.png')} 
      style={{ width: '100%', height: '50vh' }} 
      alt="Web Portal"
    />

    {/* Text in the middle of the image */}
    <div 
      style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        color: '#fff',  // Text color
        fontWeight: 'bold',
        fontSize: '24px', // Adjust size as needed
        textAlign: 'center',
        pointerEvents: 'none', // Makes the text unclickable
      }}
    >
      Seiky
    </div>
  </div>
</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>{/*end widget-span */}
                      </div>{/*end row*/}
                    </div>{/*end row-wrapper */}
                  </div>{/*end widget-span */}
                </div>{/*end row*/}
              </div>{/*end row-wrapper */}
              <div className="row-fluid-wrapper row-depth-1 row-number-6 middle_area-row-2-padding middle_area-row-2-background-layers middle_area-row-2-background-image dnd-section middle_area-row-2-force-full-width-section">
                <div className="row-fluid ">
                  <div className="span12 widget-span widget-type-cell cell_16281519336752-padding dnd-column" style={{}} data-widget-type="cell" data-x={0} data-w={12}>
                    <div className="row-fluid-wrapper row-depth-1 row-number-7 dnd-row">
                      <div className="row-fluid ">
                        <div className="span12 widget-span widget-type-custom_widget dnd-module" style={{}} data-widget-type="custom_widget" data-x={0} data-w={12}>
                          <div id="hs_cos_wrapper_widget_1628151933285" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module">
                            <div className="site-content__section seiky-platforms" style={{}}>
                              <div className="seiky-platforms__container">
                                <div className="seiky-platforms__content-wrapper">
                                  <div className="seiky-platforms__list">
                                    <div className="seiky-platforms__item  seiky-platform--bg left_group th_black" style={{backgroundImage: `url(${require('../../assets/template_assets/images/Advertiser.jpg')})`}}>
                                      
                                      <div className="seiky-platform__content-wrapper">
                                        <h2 className="seiky-platform__title">BRAND <br /> ADVERTISERS </h2>
                                        <div className="seiky-platform__content" style={{color: '#fff'}}>
                                          <p>
                                          </p><ul>
                                            <li>
                                              Reach engaged audiences across multiple platforms.
                                            </li>
                                            <li>Gain access to real-time insights and analytics.</li>
                                            <li>Use our omnichannel interface for seamless campaign management.</li>
                                          </ul>
                                          <p />
                                        </div>
                                        <a href="#" className="btn_small" style={{background: '#fff', border: '#fff', color: '#000'}}>LEARN MORE
                                        </a>
                                      </div>
                                    </div>
                                    <div className="seiky-platforms__item  seiky-platform--bg right_group th_white" style={{backgroundImage: `url(${require('../../assets/template_assets/images/Publisher.jpg')})`}}>
                                      <div className="seiky-platform__content-wrapper">
                                        <h2 className="seiky-platform__title title title--large title--black">GAME <br /> DEVELOPERS </h2>
                                        <div className="seiky-platform__content">
                                          <p>
                                          </p><ul>
                                            <li>Monetize Without Compromise:</li>
                                            <li>SEIKY makes it simple for developers to increase revenue by integrating non-intrusive ads.</li>
                                            <li>Control your ad placements and track performance metrics in real time.</li>
                                          </ul>
                                          <p />
                                        </div>
                                        <a href="https://www.portal.seiky.in/developers" className="btn_small" style={{background: '#fff', border: '#fff', color: '#000'}}>LEARN MORE
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>{/*end widget-span */}
                      </div>{/*end row*/}
                    </div>{/*end row-wrapper */}
                    <div className="row-fluid-wrapper row-depth-1 row-number-8 dnd-row">
                      <div className="row-fluid ">
                        <div className="span12 widget-span widget-type-custom_widget dnd-module" style={{}} data-widget-type="custom_widget" data-x={0} data-w={12}>
                          <div id="hs_cos_wrapper_module_1649690299008" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module">
                            <div id className="layout-1 site-content__section section section--bg trusted-partners module_1649690299008 sliderMobile_wrap">
                              {/* <div class="trusted-partners__bg-wrapper section__bg-wrapper" data-image-src="https://19825781.fs1.hubspotusercontent-na1.net/hubfs/19825781/asdaaa-min-1.webp"></div> */}
                            </div>
                          </div>
                        </div>{/*end widget-span */}
                      </div>{/*end row*/}
                    </div>{/*end row-wrapper */}
                  </div>{/*end widget-span */}
                </div>{/*end row*/}
              </div>{/*end row-wrapper */}
            </div>{/*end widget-span */}
          </div>
        </div>
      </div>
      <div className="hm-btm-sec">
        <div className="row-fluid-wrapper">
          <div className="row-fluid">
            <div className="span12 widget-span widget-type-cell " style={{}} data-widget-type="cell" data-x={0} data-w={12}>
              <div className="row-fluid-wrapper row-depth-1 row-number-1 dnd-section bottom_area-row-0-background-layers bottom_area-row-0-force-full-width-section bottom_area-row-0-background-image bottom_area-row-0-padding">
                <div className="row-fluid ">
                  <div className="span12 widget-span widget-type-cell dnd-column" style={{}} data-widget-type="cell" data-x={0} data-w={12}>
                    <div className="row-fluid-wrapper row-depth-1 row-number-2 dnd-row">
                      <div className="row-fluid ">
                        <div className="span12 widget-span widget-type-custom_widget dnd-module" style={{}} data-widget-type="custom_widget" data-x={0} data-w={12}>
                          <div id="hs_cos_wrapper_widget_1628164832390" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module">
                          </div>
                        </div>{/*end widget-span */}
                      </div>{/*end row*/}
                    </div>{/*end row-wrapper */}
                  </div>{/*end widget-span */}
                </div>{/*end row*/}
              </div>{/*end row-wrapper */}
            </div>{/*end row-wrapper */}
          <Contact />
          </div>{/*end widget-span */}
        </div>
      </div>
    </main></div>
</div>


  )
}



import Dashboard from "views/Publisher/Dashboard/Dashboard";
import Tables from "views/Dashboard/Tables.js";
import Billing from "views/Dashboard/Billing.js";
import RTLPage from "views/RTL/RTLPage.js";

import SignIn from "views/Pages/SignIn.js";

import PublisherLogin from "views/Pages/PublisherLogin";
import PublisherRegister from "views/Pages/PublisherRegister";
import SignUp from "views/Pages/SignUp.js";

// add managemenet/ingame adds module

   
import CharactersModule from "views/adsManagement/inGame/Characters";
import AdSlots from "views/Publisher/AdSlots/AdSlots";
import Sdk from "views/Publisher/SDK/Sdk";
import Profile from "views/Publisher/Profile/Profile";
import Earnings from "views/Publisher/Earnings/Earnings";
import Performance from "views/Publisher/Performance/Performance";
import Settings from "views/Publisher/Settings/Settings";

import {
  HomeIcon,
  StatsIcon,
  CreditIcon,
  PersonIcon,
  DocumentIcon,
  RocketIcon,
  SupportIcon,
  CharactersIcon,
} from "components/Icons/Icons";

import ClientCharacterList from "views/Client/ClientCharacterList";
import ClientCharacterDetails from "views/Client/ClientCharacterDetails/ClientCharacterDetails";

var PublisherRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: <HomeIcon color='inherit' />,
    component: Dashboard,
    layout: "/publisher",
  },

  
  {
    path: "/adslots",
    name: "AdSlots",
    rtlName: "لوحة القيادة",
    icon: <CharactersIcon color='inherit' />,
    component: AdSlots,
    layout: "/publisher",
  },
  {
    path: "/earnings",
    name: "Earnings",
    rtlName: "لوحة القيادة",
    icon: <CharactersIcon color='inherit' />,
    component: Earnings,
    layout: "/publisher",
  },
  {
    path: "/performance",
    name: "Performance",
    rtlName: "لوحة القيادة",
    icon: <CharactersIcon color='inherit' />,
    component: Performance,
    layout: "/publisher",
  },
  {
    path: "/settings",
    name: "Settings",
    rtlName: "لوحة القيادة",
    icon: <CharactersIcon color='inherit' />,
    component: Settings,
    layout: "/publisher",
  },

  {
    path: "/profile",
    name: "Profile",
    rtlName: "لوحة القيادة",
    icon: <CharactersIcon color='inherit' />,
    component: Profile,
    layout: "/publisher",
  },
  {
    path: "/sdk",
    name: "Sdk",
    rtlName: "لوحة القيادة",
    icon: <CharactersIcon color='inherit' />,
    component: Sdk,
    layout: "/publisher",
  },

  {
    path: "/login",
    name: "Login",
    rtlName: "لوحة القيادة",
    icon: <DocumentIcon color='inherit' />,
    component: PublisherLogin,
    layout: "/publisher-auth",
  },
  {
    path: "/register",
    name: "Register",
    rtlName: "لوحة القيادة",
    icon: <DocumentIcon color='inherit' />,
    component: PublisherRegister,
    layout: "/publisher-auth",
  },
  // {
  //   name: "ACCOUNT PAGES",
  //   category: "account",
  //   rtlName: "صفحات",
  //   state: "pageCollapse",
  //   views: [
  //     {
  //       path: "/profile",
  //       name: "Profile",
  //       rtlName: "لوحة القيادة",
  //       icon: <PersonIcon color='inherit' />,
  //       secondaryNavbar: true,
  //       component: Profile,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/signin",
  //       name: "Sign In",
  //       rtlName: "لوحة القيادة",
  //       icon: <DocumentIcon color='inherit' />,
  //       component: SignIn,
  //       layout: "/auth",
  //     },
  //     {
  //       path: "/signup",
  //       name: "Sign Up",
  //       rtlName: "لوحة القيادة",
  //       icon: <RocketIcon color='inherit' />,
  //       secondaryNavbar: true,
  //       component: SignUp,
  //       layout: "/auth",
  //     },
  //   ],
  // },
];
export default PublisherRoutes;

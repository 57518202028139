import React, { useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import {
  Box,
  Flex,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Link,
  Switch,
  Text,
  FormErrorMessage,
  DarkMode,
} from "@chakra-ui/react";
import { API_BASE_URL } from "config";
import DeveloperAuthFooter from "components/Footer/DeveloperAuthFooter";
import GradientBorder from "components/GradientBorder/GradientBorder";
import projectImage from "assets/img/ProjectImage1.png";

function DeveloperLogin() {
  const history = useHistory();
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [errors, setErrors] = useState({});

  const handleSignIn = async (e) => {
    e.preventDefault();

    console.log("Email:", email);
    console.log("Password:", password);
    try {
      const response = await fetch(`${API_BASE_URL}/developer/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },

        body: JSON.stringify(email || password ? { email, password } : {}),


      });



      if (response.ok) {
        const data = await response.json();
        localStorage.setItem("developerToken", data.token);
        history.push("/developer/dashboard");
      } else {
        const errorData = await response.json();
        if (errorData.errors) {
          setErrors(errorData.errors || []);
        } else {
          console.log("da", errorData.message)
          setErrors(errorData);
        }

      }
    } catch (error) {
      console.error("Login error:", error);
      alert("An error occurred during login.");
    }
  };

  const titleColor = "white";
  const textColor = "gray.400";

  return (
    <Flex position="relative">
      <Flex
        minH="100vh"
        h={{ base: "120vh", lg: "fit-content" }}
        w="100%"
        maxW="1044px"
        mx="auto"
        pt={{ sm: "100px", md: "0px" }}
        flexDirection="column"
        me={{ base: "auto", lg: "50px", xl: "auto" }}>
        <Flex
          alignItems="center"
          justifyContent="start"
          style={{ userSelect: "none" }}
          mx={{ base: "auto", lg: "unset" }}
          ms={{ base: "auto", lg: "auto" }}
          w={{ base: "100%", md: "50%", lg: "450px" }}
          px="50px">
          <Flex
            direction="column"
            w="100%"
            background="transparent"
            mt={{ base: "50px", md: "150px", lg: "160px", xl: "245px" }}
            mb={{ base: "60px", lg: "95px" }}>
            <Heading color={titleColor} fontSize="32px" mb="10px">
              Welcome Back, Developer!
            </Heading>
            <Text
              mb="36px"
              ms="4px"
              color={textColor}
              fontWeight="bold"
              fontSize="14px">
              Enter your email and password to sign in
            </Text>
            <FormControl isInvalid={(Array.isArray(errors) && errors[0]?.path === 'email') ? true : false}>
              <FormLabel
                ms="4px"
                fontSize="sm"
                fontWeight="normal"
                color="white">
                Email
              </FormLabel>
              <GradientBorder
                mb="24px"
                w={{ base: "100%", lg: "fit-content" }}
                borderRadius="20px">
                <Input
                  color="white"
                  bg="rgb(19,21,54)"
                  border="transparent"
                  borderRadius="20px"
                  fontSize="sm"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  size="lg"
                  w={{ base: "100%", md: "346px" }}
                  maxW="100%"
                  h="46px"
                  placeholder="Your email address"
                />
              </GradientBorder>
              {errors[0]?.msg && <FormErrorMessage>{errors[0]?.msg}</FormErrorMessage>}
            </FormControl>
            <FormControl isInvalid={(errors[1]?.path === "password") ? true : false}>
              <FormLabel
                ms="4px"
                fontSize="sm"
                fontWeight="normal"
                color="white">
                Password
              </FormLabel>
              <GradientBorder
                mb="24px"
                w={{ base: "100%", lg: "fit-content" }}
                borderRadius="20px">
                <Input
                  color="white"
                  bg="rgb(19,21,54)"
                  border="transparent"
                  borderRadius="20px"
                  fontSize="sm"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  size="lg"
                  w={{ base: "100%", md: "346px" }}
                  maxW="100%"
                  type="password"
                  placeholder="Your password"
                />
              </GradientBorder>
              {errors[1]?.msg && <FormErrorMessage>{errors[1]?.msg || errors[1]?.msg}</FormErrorMessage>}
            </FormControl>
            <FormControl isInvalid={errors?.message || errors?.message}>
              {errors?.message && <FormErrorMessage>{errors.message}</FormErrorMessage>}
            </FormControl>

            <FormControl display="flex" alignItems="center">
              <DarkMode>
                <Switch id="remember-login" colorScheme="brand" me="10px" />
              </DarkMode>
              <FormLabel
                htmlFor="remember-login"
                mb="0"
                ms="1"
                fontWeight="normal"
                color="white">
                Remember me
              </FormLabel>
            </FormControl>
            <Button
              variant="brand"
              fontSize="10px"
              type="submit"
              w="100%"
              maxW="350px"
              h="45"
              onClick={handleSignIn}
              mb="20px"
              mt="20px">
              SIGN IN
            </Button>

            <Flex
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              maxW="100%"
              mt="0px">
              <Text color={textColor} fontWeight="medium">
                Don't have an account?
                <NavLink to="/developer-auth/register" style={{ color: titleColor, marginLeft: "5px", fontWeight: "bold" }}>
                  Sign Up
                </NavLink>
              </Text>
            </Flex>
          </Flex>
        </Flex>
        <Box
          w={{ base: "335px", md: "450px" }}
          mx={{ base: "auto", lg: "unset" }}
          ms={{ base: "auto", lg: "auto" }}
          mb="80px">
          <DeveloperAuthFooter />
        </Box>
        <Box
          display={{ base: "none", lg: "block" }}
          overflowX="hidden"
          h="100%"
          maxW={{ md: "50vw", lg: "50vw" }}
          minH="100vh"
          w="960px"
          position="absolute"
          left="0px">
          <Box
            bgImage={projectImage}
            w="100%"
            h="100%"
            bgSize="cover"
            bgPosition="50%"
            position="absolute"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center">
            <Text
              textAlign="center"
              color="white"
              letterSpacing="8px"
              fontSize="20px"
              fontWeight="500">
              INSPIRED BY THE FUTURE:
            </Text>
            <Text
              textAlign="center"
              color="transparent"
              letterSpacing="8px"
              fontSize="36px"
              fontWeight="bold"
              bgClip="text !important"
              bg="linear-gradient(94.56deg, #FFFFFF 79.99%, #21242F 102.65%)">
              THE SEIKY
            </Text>
          </Box>
        </Box>
      </Flex>
    </Flex>
  );
}

export default DeveloperLogin;

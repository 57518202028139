// src/components/Profile.js

import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Text,
  Button,
  IconButton,
  Spinner,
  useToast,
  Link,
  Tooltip,
} from "@chakra-ui/react";
import { FaCopy, FaEye, FaEyeSlash, FaSyncAlt } from "react-icons/fa";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { API_BASE_URL } from "config";


const Profile = () => {
  const history = useHistory();
  const toast = useToast();

  const [profile, setProfile] = useState(null);
  const [maskedApiKey, setMaskedApiKey] = useState("");
  const [isMasked, setIsMasked] = useState(true);
  const [loading, setLoading] = useState(true);
  const [regenerating, setRegenerating] = useState(false);
  const [error, setError] = useState("");

  // Retrieve token from localStorage
  const token = localStorage.getItem("publisherToken");

  useEffect(() => {
    // If no token, redirect to login
    if (!token) {
      toast({
        title: "Authentication Required",
        description: "Please log in to access your profile.",
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
      history.push("/publisher-auth/login"); // Adjust the path as necessary
      return;
    }

    // Fetch profile data
    const fetchProfile = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/publisher/profile`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.data && response.data.profile) {
          setProfile(response.data.profile);
          setMaskedApiKey(maskKey(response.data.profile.apiKey));
        } else {
          throw new Error("Invalid response from server.");
        }
      } catch (err) {
        console.error("Error fetching profile:", err);
        if (err.response && err.response.status === 401) {
          // Token invalid or expired
          toast({
            title: "Session Expired",
            description: "Please log in again.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
          localStorage.removeItem("publisherToken");
          history.push("/publisher-auth/login"); // Adjust the path as necessary
        } else {
          setError("Failed to fetch profile information.");
          toast({
            title: "Error",
            description: "Failed to fetch profile information.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
      } finally {
        setLoading(false);
      }
    };

    fetchProfile();
   
  }, [token]);

  // Function to mask API key
  const maskKey = (key) => {
    if (key.length <= 8) return key;
    const maskedSection = key.slice(0, -8).replace(/./g, "*");
    const visibleSection = key.slice(-8);
    return `${maskedSection}${visibleSection}`;
  };

  // Toggle API key masking
  const toggleMask = () => {
    setIsMasked(!isMasked);
  };

  // Copy API key to clipboard
  const handleCopy = () => {
    const keyToCopy = profile.apiKey;
    navigator.clipboard
      .writeText(keyToCopy)
      .then(() => {
        toast({
          title: "Copied",
          description: "API Key has been copied to clipboard.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      })
      .catch(() => {
        toast({
          title: "Error",
          description: "Failed to copy API Key.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });
  };

  // Regenerate API key
  const handleRegenerate = async () => {
    const confirm = window.confirm(
      "Are you sure you want to regenerate your API key? This will invalidate the current key."
    );
    if (!confirm) return;

    setRegenerating(true);
    try {
      const response = await axios.post(
        `${API_BASE_URL}/user/api-key/regenerate`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data && response.data.apiKey) {
        setProfile((prevProfile) => ({
          ...prevProfile,
          apiKey: response.data.apiKey,
        }));
        setMaskedApiKey(maskKey(response.data.apiKey));
        setIsMasked(true); // Reset to masked view
        toast({
          title: "Success",
          description: "API Key regenerated successfully.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      } else {
        throw new Error("Invalid response from server.");
      }
    } catch (err) {
      console.error("Error regenerating API key:", err);
      if (err.response && err.response.status === 401) {
        // Token invalid or expired
        toast({
          title: "Session Expired",
          description: "Please log in again.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        localStorage.removeItem("publisherToken");
        history.push("/publisher-auth/login"); // Adjust the path as necessary
      } else {
        toast({
          title: "Error",
          description: "Failed to regenerate API key.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } finally {
      setRegenerating(false);
    }
  };

  // If loading, show spinner
  if (loading) {
    return (
      <Flex justify="center" align="center" minH="100vh" bg="gray.100">
        <Spinner size="xl" color="blue.500" />
      </Flex>
    );
  }

  // If error exists, display error message
  if (error) {
    return (
      <Flex justify="center" align="center" minH="100vh" bg="gray.100">
        <Text color="red.500" fontSize="lg">
          {error}
        </Text>
      </Flex>
    );
  }

  return (
    <Flex justify="center" align="center" p={4}  minH="100vh">
      <Box
        maxW="930px"
        w="100%"
        bg="white"
        boxShadow="lg"
        borderRadius="md"
        p={6}
      >
        <Flex direction={{ base: "column", md: "row" }} gap={6}>
          {/* Profile Details */}
          <Box flex="1">
            <Text fontSize="2xl" fontWeight="bold" mb={4}>
              Publisher Profile
            </Text>

            <Box mb={4}>
              <Text fontWeight="bold">Company Name:</Text>
              <Text>{profile.companyName}</Text>
            </Box>

            <Box mb={4}>
              <Text fontWeight="bold">Website URL:</Text>
              <Link
                href={`https://${profile.websiteURL}`}
                color="blue.500"
                isExternal
              >
                {profile.websiteURL}
              </Link>
            </Box>

            <Box mb={4}>
              <Text fontWeight="bold">Contact Email:</Text>
              <Link href={`mailto:${profile.contactEmail}`} color="blue.500">
                {profile.contactEmail}
              </Link>
            </Box>

            <Box mb={4}>
              <Text fontWeight="bold">Contact Phone:</Text>
              <Link href={`tel:${profile.contactPhone}`} color="blue.500">
                {profile.contactPhone}
              </Link>
            </Box>

            {/* <Box mb={4}>
              <Text fontWeight="bold">User ID:</Text>
              <Text>{profile.userId}</Text>
            </Box>

            <Box mb={4}>
              <Text fontWeight="bold">Profile ID:</Text>
              <Text>{profile._id}</Text>
            </Box>

            <Box mb={4}>
              <Text fontWeight="bold">Created At:</Text>
              <Text>
                {new Date(profile.created_at).toLocaleString("en-US", {
                  dateStyle: "medium",
                  timeStyle: "short",
                })}
              </Text>
            </Box>

            <Box mb={4}>
              <Text fontWeight="bold">Last Updated:</Text>
              <Text>
                {new Date(profile.updated_at).toLocaleString("en-US", {
                  dateStyle: "medium",
                  timeStyle: "short",
                })}
              </Text>
            </Box> */}
          </Box>

          {/* API Key Management */}
          <Box flex="1" bg="gray.50" p={4} borderRadius="md">
            <Flex justify="space-between" align="center" mb={2}>
              <Text fontSize="lg" fontWeight="bold">
                API Key
              </Text>
              <Tooltip
                label={isMasked ? "Reveal API Key" : "Hide API Key"}
                aria-label="Toggle API Key Visibility"
              >
                <IconButton
                  aria-label={isMasked ? "Reveal API Key" : "Hide API Key"}
                  icon={isMasked ? <FaEye /> : <FaEyeSlash />}
                  variant="ghost"
                  onClick={toggleMask}
                />
              </Tooltip>
            </Flex>
            <Flex
              bg="white"
              p={3}
              borderRadius="md"
              align="center"
              mb={4}
              boxShadow="sm"
            >
              <Text fontFamily="monospace" flex="1" isTruncated>
                {isMasked ? maskedApiKey : profile.apiKey}
              </Text>
              <Tooltip label="Copy API Key" aria-label="Copy API Key">
                <IconButton
                  aria-label="Copy API Key"
                  icon={<FaCopy />}
                  variant="ghost"
                  onClick={handleCopy}
                />
              </Tooltip>
            </Flex>
            {/* <Button
              leftIcon={<FaSyncAlt />}
              colorScheme="red"
              variant="outline"
              onClick={handleRegenerate}
              isLoading={regenerating}
              loadingText="Regenerating"
              w="full"
            >
              Regenerate API Key
            </Button> */}
            <Text fontSize="sm" color="gray.500" mt={2}>
              🔒 Keep your API key secure. Do not share it publicly.
            </Text>
          </Box>
        </Flex>
      </Box>
    </Flex>
  );
};

export default Profile;
